<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    :stateless="disableClickOutside"
    right
    id="campaign-view-mode-panel"
    width="900px"
    class="send-egift-panel"
  >
    <panel-header
      :show.sync="show"
      :step="step === 10 ? 1 : step"
      @update:step="handleStepChange"
    >
      {{ panelTitle }}
    </panel-header>

    <v-slide-x-reverse-transition
      v-if="show"
      group
      tag="div"
      hide-on-leave
      class="send-egift-panel__content-wrapper"
    >
      <recipients-step
        v-if="step === 1"
        v-bind="{ ...$props }"
        :key="1"
        :recipients="recipients"
        :recipientMethod="recipientMethod"
        :singleGiftLinkRecipients="singleGiftLinkRecipients"
        @update:recipients="(val) => recipients = val"
        @update:recipientMethod="(val) => recipientMethod = val"
        @update:singleGiftLinkRecipients="(val) => singleGiftLinkRecipients = val"
        @update:linkType="(val) => form.linkType = val"
        @update:step="(val) => step = val"
      />

      <recipients-bulk-options-step
        v-else-if="step === 2"
        v-bind="{ ...$props }"
        :key="2"
        @update:recipients="(val) => recipients = val"
        @update:recipientMethod="(val) => recipientMethod = val"
        @update:step="(val) => step = val"
      />

      <recipients-copy-paste-step
        v-else-if="step === 3"
        v-bind="{ ...$props }"
        :key="3"
        :recipients="recipients"
        :singleGiftLinkRecipients="singleGiftLinkRecipients"
        @update:recipients="(val) => recipients = val"
        @update:step="(val) => step = val"
      />

      <recipients-contacts-step
        v-else-if="step === 4"
        v-bind="{ ...$props }"
        :key="4"
        :recipients="recipients"
        :singleGiftLinkRecipients="singleGiftLinkRecipients"
        @update:recipients="(val) => recipients = val"
        @update:step="(val) => step = val"
      />

      <review-recipients-step
        v-else-if="step === 5"
        v-bind="{ ...$props }"
        :key="5"
        :recipients="recipients"
        :recipientMethod="recipientMethod"
        :singleGiftLinkRecipients="singleGiftLinkRecipients"
        @update:recipients="(val) => recipients = val"
        @update:linkType="(val) => form.linkType = val"
        @update:recipientMethod="(val) => recipientMethod = val"
        @update:singleGiftLinkRecipients="(val) => singleGiftLinkRecipients = val"
        @update:step="(val) => step = val"
      />

      <review-step
        v-else-if="step === 6"
        v-bind="{ ...$props }"
        :key="6"
        :form="form"
        :recipients="recipients"
        :approvalForm="approvalForm"
        :recipientMethod="recipientMethod"
        :defaultPayment="defaultPaymentMethod"
        :campaignTotalCost="campaignTotalCost"
        :campaignShippingCost="campaignShippingCost"
        :checkoutNeedsApproval="campaignNeedsApproval"
        :singleGiftLinkRecipients="singleGiftLinkRecipients"
        :isSpendLimitInsufficient="isSpendLimitInsufficient"
        @update:description="(val) => form.description = val"
        @update:emailSubjectLine="(val) => form.emailSubjectLine = val"
        @update:welcomeMessage="(val) => form.welcomeMessage = val"
        @update:giftGiverName="(val) => form.giftGiverName = val"
        @update:giftGiverEmail="(val) => form.giftGiverEmail = val"
        @update:videoGreeting="(val) => form.videoGreeting = val"
        @update:calendlyLink="(val) => form.calendlyLink = val"
        @update:linkType="(val) => form.linkType = val"
        @update:recipientCanUpgrade="(val) => form.settings.recipientCanUpgrade = val"
        @update:recipientCanReEgift="(val) => form.settings.recipientCanReEgift = val"
        @update:disableEmailReminders="(val) => form.settings.disableEmailReminders = val"
        @update:shippingArrivalDate="(val) => form.shippingArrivalDate = val"
        @update:paymentMethod="(val) => form.payment.method = val"
        @update:paymentData="(val) => form.payment.paymentData = val"
        @update:splitPaymentData="(val) => form.payment.splitPaymentData = val"
        @update:successResponse="(val) => successResponse = val"
        @update:isAnotherPanelOpened="(val) => isAnotherPanelOpened = val"
        @update:campaignTotalCost="(val) => campaignTotalCost = val"
        @update:campaignShippingCost="(val) => campaignShippingCost = val"
        @update:step="(val) => step = val"
      />

      <more-options-step
        v-else-if="step === 7"
        v-bind="{ ...$props }"
        :key="7"
        :form="form"
        @update:description="(val) => form.description = val"
        @update:shippingArrivalDate="(val) => form.shippingArrivalDate = val"
        @update:disableEmailReminders="(val) => form.settings.disableEmailReminders = val"
        @update:videoGreeting="(val) => form.videoGreeting = val"
        @update:calendlyLink="(val) => form.calendlyLink = val"
        @update:expirationDays="(val) => form.expirationDays = val"
        @update:actionLinkType="(val) => form.actionLinkType = val"
        @update:freeForm="(val) => form.freeForm = val"
        @update:step="(val) => step = val"
      />

      <greetings-step
        v-else-if="step === 8"
        :key="8"
        :form="form"
        :recipients="recipients"
        :greetingHelper="greetingHelper"
        :singleGiftLinkRecipients="singleGiftLinkRecipients"
        @update:greetingHelper="(val) => greetingHelper = val"
        @update:greeting="(val) => form.greeting = val"
        @update:logoUrl="(val) => form.logoUrl = val"
        @update:step="(val) => step = val"
      />

      <logos-and-images-step
        v-else-if="step === 9"
        :key="9"
        :form="form"
        @update:logoUrl="(val) => form.logoUrl = val"
        @update:step="(val) => step = val"
      />

      <success-step
        v-else-if="step === 10"
        v-bind="{ ...$props }"
        :key="10"
        :form="form"
        :approvalForm="approvalForm"
        :successResponse="successResponse"
        :recipientMethod="recipientMethod"
        :checkoutNeedsApproval="campaignNeedsApproval"
      />

      <refund-policy-step v-else-if="step === 11" :key="11" />

      <deposit-info-step v-else-if="step === 12" :key="12" />

      <approval-step
        v-else-if="step === 13"
        v-bind="{ ...$props }"
        :key="13"
        :form="form"
        :recipients="recipients"
        :approvalForm="approvalForm"
        :recipientMethod="recipientMethod"
        :campaignTotalCost="campaignTotalCost"
        :campaignShippingCost="campaignShippingCost"
        :checkoutNeedsApproval="campaignNeedsApproval"
        :singleGiftLinkRecipients="singleGiftLinkRecipients"
        @update:step="(val) => step = val"
        @update:successResponse="(val) => successResponse = val"
      />

      <greeting-ideas-step
        v-else-if="step === 14"
        v-bind="{ ...$props }"
        :key="14"
        :greetingHelper="greetingHelper"
        @update:greetingHelper="(val) => greetingHelper = val"
        @update:step="(val) => step = val"
      />
    </v-slide-x-reverse-transition>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Mixins, Component, Watch, ProvideReactive } from 'vue-property-decorator'
import { RootSteps, RecipientMethod, ICampaignSettings, LinkType, PaymentType } from './types'
import type {
  IRecipient,
  ISendEgiftForm,
  ISendCampaignResponse,
  IApprovalForm,
  IBudgetEntity,
  IGreetingHelper,
} from './types'

import panelVModel from '@/mixins/panelVModel'
import userInfo from '@/mixins/userInfo'
import panelTypeProp from './sendEgift/mixins/panelTypeProp'
import campaignProp from './sendEgift/mixins/campaignProp'

import { beginCheckout } from '@/plugins/googleTagManager'

import PanelHeader from '@/components/PanelHeader.vue'
import RecipientsStep from './sendEgift/steps/SendEgiftPanelRecipients.vue'
import RecipientsBulkOptionsStep from './sendEgift/steps/SendEgiftPanelRecipientsBulkOptions.vue'
import RecipientsCopyPasteStep from './sendEgift/recipients/SendEgiftPanelRecipientsCopyPaste.vue'
import RecipientsContactsStep from './sendEgift/recipients/SendEgiftPanelRecipientsContacts.vue'
import ReviewRecipientsStep from './sendEgift/steps/SendEgiftPanelReviewRecipients.vue'
import ReviewStep from './sendEgift/steps/SendEgiftPanelReview.vue'
import MoreOptionsStep from './sendEgift/steps/SendEgiftPanelMoreOptions.vue'
import GreetingsStep from './sendEgift/steps/SendEgiftPanelGreeting.vue'
import LogosAndImagesStep from './sendEgift/steps/SendEgiftPanelLogos.vue'
import SuccessStep from './sendEgift/steps/SendEgiftPanelSuccess.vue'
import RefundPolicyStep from './sendEgift/steps/SendEgiftPanelRefundPolicy.vue'
import DepositInfoStep from './sendEgift/steps/SendEgiftPanelDepositInfo.vue'
import GreetingIdeasStep from './sendEgift/steps/SendEgiftPanelGreetingIdeas.vue'
import ApprovalStep from './sendEgift/steps/SendEgiftPanelApproval.vue'

@Component({
  mixins: [panelVModel],
  components: {
    PanelHeader,
    RecipientsStep,
    ReviewStep,
    ApprovalStep,
    MoreOptionsStep,
    GreetingsStep,
    GreetingIdeasStep,
    LogosAndImagesStep,
    ReviewRecipientsStep,
    RecipientsBulkOptionsStep,
    RecipientsContactsStep,
    RecipientsCopyPasteStep,
    SuccessStep,
    RefundPolicyStep,
    DepositInfoStep,
  },
})
export default class SendEgiftPanel extends Mixins(
  userInfo,
  campaignProp,
  panelTypeProp
) {
  step: RootSteps = RootSteps.Recipient;

  recipientMethod: RecipientMethod = RecipientMethod.Manually;

  loading = false;

  campaignTotalCost: number = 0;

  campaignShippingCost: number = 0;

  recipients: Array<IRecipient> = [];

  singleGiftLinkRecipients = 0;

  isAnotherPanelOpened = false;

  isBeginCheckoutEventSend = false;

  // used to handle data between GreetingMessage and GreetingIdeas steps
  greetingHelper: IGreetingHelper = {
    message: null,
    logoUrl: null,
  }

  form: ISendEgiftForm = {
    description: null,
    from: 'Corporategift.com',
    emailSubjectLine: null,
    greeting: null,
    welcomeMessage: null,
    logoUrl: null,
    giftGiverName: null,
    giftGiverEmail: null,
    videoGreeting: null,
    expirationDays: 90,
    calendlyLink: null,
    actionLinkType: null,
    freeForm: {
      buttonText: null,
      link: null,
    },
    settings: {
      recipientCanUpgrade: true,
      recipientCanReEgift: true,
      disableEmailReminders: false,
    },
    payment: {
      method: PaymentType.BE,
      paymentData: null,
      allowSplitPayment: true,
      splitPaymentData: null,
    },
    linkType: LinkType.StandardLink,
    shippingArrivalDate: null,
  };

  defaultPaymentMethod: PaymentType | false = false;

  approvalForm: IApprovalForm = {};

  successResponse: ISendCampaignResponse | null = null;

  @ProvideReactive() refreshTokenFn: Function | null = null;

  @ProvideReactive() updateRefreshTokenFn = this.updateInjectedPaymentMethod

  updateInjectedPaymentMethod (fn: Function) {
    this.refreshTokenFn = fn
  }

  get panelTitle(): string {
    const { step } = this

    if (step === RootSteps.Recipient) { return 'Send campaign to:' }
    if (step === RootSteps.RecipientBulkOptions) { return 'Bulk upload' }
    if (step === RootSteps.RecipientBulkCopyPaste) { return 'Bulk copy & paste' }
    if (step === RootSteps.RecipientBulkContacts) { return 'Select from contacts' }
    if (step === RootSteps.ReviewRecipients) { return 'Review recipients' }
    if (step === RootSteps.Review) { return 'Review & send' }
    if (step === RootSteps.MoreOptions) { return 'More options' }
    if (step === RootSteps.GreetingMessage) { return 'Edit greeting message' }
    if (step === RootSteps.GreetingIdeas) { return 'Greeting ideas' }
    if (step === RootSteps.LogosAndImages) { return 'My logos & images' }
    if (step === RootSteps.RefundPolicy) { return 'eGift refund policy' }
    if (step === RootSteps.DepositInfo) { return 'How is the deposit calculated' }
    if (step === RootSteps.Success) { return 'Order successfully placed' }
    if (step === RootSteps.Approval) { return 'Approval request' }

    return 'Send E-Gift Panel'
  }

  get campaignSettings(): ICampaignSettings {
    return this.campaign?.egift_settings ?? {}
  }

  get isVideoPreviewVisible(): boolean {
    return this.$store.state?.app?.showVideoModal ?? false
  }

  get disableClickOutside(): boolean {
    const { isVideoPreviewVisible, isAnotherPanelOpened } = this

    return isAnotherPanelOpened || isVideoPreviewVisible
  }

  get budgetEntityRequestApproval(): boolean {
    const { method, paymentData } = this.form.payment

    if (method === PaymentType.BE) { return (paymentData as IBudgetEntity)?.approve ?? false; }
    return false
  }

  get campaignNeedsApproval(): boolean {
    const { budgetEntityRequestApproval } = this

    const checkoutNeedsApproval = this.$store.state.header?.headerData?.checkoutNeedsApproval ?? false

    return checkoutNeedsApproval || budgetEntityRequestApproval || false
  }

  get isSpendLimitInsufficient(): boolean {
    const { campaignTotalCost } = this
    const isSubAccount: boolean = this.$store.state?.header?.headerData?.isSubaccount ?? false
    const checkoutNeedsApproval: boolean = this.$store.state?.header?.headerData?.checkoutNeedsApproval ?? false
    const spendLimit: number | null = this.$store.state?.header?.headerData?.spendLimit ?? null

    if (!isSubAccount) { return false }
    if (checkoutNeedsApproval) { return false }
    if (spendLimit === null) { return false }

    return Number(spendLimit) < campaignTotalCost
  }

  @Watch('show')
  noShowChange (val: boolean) {
    if (val) {
      const getDefaultData = this.$options.data as { apply: (args: unknown) => void }
      Object.assign(this.$data, getDefaultData.apply(this))

      setTimeout(() => { this.setCampaignSavedFormData() }, 0)
    }
  }

  @Watch('campaignTotalCost')
  onTotalCostChange () {
    this.tryToSendBeginCheckoutEvent()
  }

  handleStepChange(val: RootSteps): void {
    const { recipientMethod } = this

    if ((val + 1) === RootSteps.RecipientBulkContacts) {
      this.step = RootSteps.RecipientBulkOptions
      return
    }

    if ((val + 1) === RootSteps.GreetingIdeas) {
      this.step = RootSteps.GreetingMessage
      return
    }

    if ((val + 1) === RootSteps.ReviewRecipients) {
      if (recipientMethod === RecipientMethod.Contacts) {
        this.step = RootSteps.RecipientBulkContacts
      } else if (recipientMethod === RecipientMethod.ImportFile) {
        this.step = RootSteps.RecipientBulkOptions
      } else if (recipientMethod === RecipientMethod.ImportCopyPaste) {
        this.step = RootSteps.RecipientBulkCopyPaste
      } else if (recipientMethod === RecipientMethod.Manually) {
        this.step = RootSteps.Recipient 
      }
    } else {
      this.step = val > RootSteps.Review ? RootSteps.Review : val
      // clear greetingHelper when a user leaves the greeting step
      this.greetingHelper = {
        logoUrl: null,
        message: null,
      }
    }
  }

  setCampaignSavedFormData (): void {
    const { campaignSettings: settings, userFullName, userEmail, campaign } = this
    const defaultPaymentMethod = campaign?.payment?.method

    this.form.settings = {
      recipientCanUpgrade: settings.is_upgrade_enabled ?? true,
      recipientCanReEgift: settings.is_regift_enabled ?? true,
      disableEmailReminders: settings.disable_recipient_reminders ?? false,
    }

    if (defaultPaymentMethod) {
      const paymentMethod = defaultPaymentMethod === 'cc'
        ? PaymentType.CC
          : defaultPaymentMethod === 'ach'
          ? PaymentType.ACH
        : PaymentType.BE

      this.form.payment.method = paymentMethod
      this.defaultPaymentMethod = paymentMethod
    }

    if (campaign.use_calendly_link) { this.form.actionLinkType = 'calendly' }
    if (campaign.use_free_form_url) { this.form.actionLinkType = 'free-form' }

    this.form.giftGiverEmail = userEmail ?? null
    this.form.giftGiverName = (campaign?.gift_giver_name || userFullName) ?? null
    this.form.emailSubjectLine =  campaign?.email_subject_line
      || `Surprise! You received a gift from ${userFullName || '<Gift giver name>'}`
    this.form.greeting = campaign.greeting_message ? `\n\n${campaign.greeting_message}\n\n` : null
    this.form.description = campaign.description ?? null
    this.form.calendlyLink = campaign.calendly_link ?? null
    this.form.freeForm.buttonText = campaign.free_form_button ?? null
    this.form.freeForm.link = campaign.free_form_url ?? null
    this.form.videoGreeting = campaign.video_greeting ?? null
    this.form.logoUrl = (campaign.logo_url
      || `https://cfstage.corporategift.com/media/personalize/${campaign?.customer_group?.logo}`)
      ?? null
    this.form.expirationDays = campaign.expire_after || 90
  }

  tryToSendBeginCheckoutEvent (): void {
    const {
      isBeginCheckoutEventSend,
      campaign,
      campaignTotalCost,
      recipients,
      singleGiftLinkRecipients,
    } = this

    if (!isBeginCheckoutEventSend) {
      beginCheckout({
        value: campaignTotalCost?.toFixed(2),
        checkoutType: 'campaign',
        coupon: undefined,
        items: campaign.collection?.items?.map((item) => ({
          'item_id': item?.product?.entity_id ?? undefined,
          'item_name': item?.product?.name ?? undefined,
          'item_category': 'egift_campaign',
          'price': (item?.product?.price ?? 0)?.toFixed(2),
          'quantity': recipients?.length || singleGiftLinkRecipients || 1,
          'currency': 'USD',
        }))
      })

      this.isBeginCheckoutEventSend = true
    }
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel {
  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 50px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
</style>
